<template>
  <div>
    <!-- section-1  -->
    <v-card class="bgs rounded-0" flat  >
      <div class="" id="home-tops">
        <v-row align="center" class="ma-0">
          <v-col cols="12" md="6" sm="12" class="">
            <div class="left">
              <p class="text-lg-h3 text-sm-h6 text-body-1 font-weight-bold" style="letter-spacing: 2px">
                {{ $t("taoify 一站式") }}
              </p>
              <p class="text-lg-h3 text-sm-h6 text-body-1 font-weight-bold ">{{ $t("全球跨境电商平台") }}</p>
              <p class="mt-0 text-body-2 mb-8">
                {{
                  $t("B2B、B2C垂直领域专家，旨在帮助供应商一站直达全球消费者，触达海量优质市场，把商品销往全球")
                }}
              </p>
              <div class="mt-8">
                <router-link to="/sign-up" class="d-flex">
                  <v-card
                    class="primary-background font18 px-12 rounded-pill cursor white--text"
                    height="51"
                    flat
                    >{{ $t("即刻入驻，商机无限") }}</v-card
                  >
                </router-link>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="6" sm="12" class="px-0 d-flex justify-end py-10">
            <div>
              <v-img
              class="gif"
                max-width="595"
                src="@/assets/images/recruit/image1.png"
              ></v-img>
              <p class="mb-0 text-center primary--text mt-8">
                {{ $t("收录不限制品类，全球用户一站直达") }}
              </p>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-container>
      <p class="font32 font-weight-bold text-center" style="margin-top: 66px">
        {{ $t("我们的优势") }}
      </p>

      <v-row class="ma-0">
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/recruit/icon1.png"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">{{ $t("有买家") }}</p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("已有月活7亿的海外消费群体") }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/recruit/icon2.png"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">{{ $t("有资源") }}</p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{
                $t("金流、物流、营销、管理一站式搞定，你只需要把商品交给我们")
              }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/recruit/icon3.png"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">
              {{ $t("有效率") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("多种自动化工具，赋能运营，智能AI助力") }}
            </p>
          </v-card>
        </v-col>
        <v-col cols="6" md="3" class="">
          <v-card flat width="160" class="mx-auto">
            <div class="d-flex justify-center">
              <div>
                <v-img
                  width="140"
                  class=""
                  src="@/assets/images/recruit/icon4.png"
                ></v-img>
              </div>
            </div>
            <p class="text-center font20 weight500 mb-2">
              {{ $t("有经验") }}
            </p>
            <p class="text--secondary text-body-2 mb-0 text-center">
              {{ $t("12年跨境经验，服务上千家企业，行业内高价值生态伙伴多") }}
            </p>
          </v-card>
        </v-col>
      </v-row>
      <p
        class="font32 font-weight-bold text-center mb-11"
        style="margin-top: 66px"
      >
        {{ $t("taoify渠道商") }}
      </p>
      <v-row align="center">
        <v-col cols="6" md="3" class="pa-0 color1" style="">
          <div>
            <v-img
              width="60"
              class="mx-auto"
              src="@/assets/images/recruit/guoqi1.png"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a href="http://facilbuy.taoify.com" target="_blank">
            <div>
              <v-img
                width="135"
                class="mx-auto"
                src="@/assets/images/recruit/logo1.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a href="https://www.fjjhgjwl.com/" target="_blank">
            <div>
              <v-img
                width="178"
                class="mx-auto"
                src="@/assets/images/recruit/logo2.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a href="https://member.hktwbuy.com/" target="_blank">
            <div>
              <v-img
                width="78"
                class="mx-auto"
                src="@/assets/images/recruit/logo3.png"
              ></v-img>
            </div>
          </a>
        </v-col>

        <v-col cols="6" md="3" class="pa-0 color2" style="">
          <div>
            <v-img
              width="60"
              class="mx-auto"
              src="@/assets/images/recruit/guoqi2.png"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="https://www.77777.style/" target="_blank">
            <div>
              <v-img
                width="172.5"
                class="mx-auto"
                src="@/assets/images/recruit/logo4.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="https://www.bamboo-daikou.com/" target="_blank">
            <div>
              <v-img
                width="181.27"
                class="mx-auto"
                src="@/assets/images/recruit/logo5.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="https://www.buychance.jp/" target="_blank">
            <div>
              <v-img
                width="123.88"
                class="mx-auto"
                src="@/assets/images/recruit/logo6.png"
              ></v-img>
            </div>
          </a>
        </v-col>

        <v-col cols="6" md="3" class="pa-0 color1" style="">
          <div>
            <v-img
              width="60"
              class="mx-auto"
              src="@/assets/images/recruit/guoqi3.png"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a href="https://www.echanmall.com/" target="_blank">
            <div>
              <v-img
                width="171.82"
                class="mx-auto"
                src="@/assets/images/recruit/logo7.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a
            href="https://apps.apple.com/cn/app/cavamall/id1631268362"
            target="_blank"
          >
            <div>
              <v-img
                width="186.46"
                class="mx-auto"
                src="@/assets/images/recruit/logo8.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color1">
          <a href="http://www.mimobuy.com/" target="_blank">
            <div>
              <v-img
                width="63.65"
                class="mx-auto"
                src="@/assets/images/recruit/logo9.png"
              ></v-img>
            </div>
          </a>
        </v-col>

        <v-col cols="6" md="3" class="pa-0 color2" style="">
          <div>
            <v-img
              width="60"
              class="mx-auto"
              src="@/assets/images/recruit/guoqi4.png"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="https://www.atpdigo.com/" target="_blank">
            <div>
              <v-img
                width="101.07"
                class="mx-auto"
                src="@/assets/images/recruit/logo10.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="https://apps.apple.com/cn/app/taromall/id6446935233" target="_blank">
            <div>
              <v-img
                width="153.85"
                class="mx-auto"
                src="@/assets/images/recruit/logo11.png"
              ></v-img>
            </div>
          </a>
        </v-col>
        <v-col cols="6" md="3" class="pa-0 color2">
          <a href="http://urakitai.taoify.com/" target="_blank">
            <div>
              <v-img
                width="103.6"
                class="mx-auto"
                src="@/assets/images/recruit/logo12.png"
              ></v-img>
            </div>
          </a>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      color="#F7F7F7"
      class="py-15 rounded-0"
      style="margin-top: 66px"
      flat
    >
      <v-container class="pa-0">
        <p class="mb-11 font32 text-center font-weight-bold">
          {{ $t("taoify渠道商入驻流程") }}
        </p>
        <v-img
          max-width="1200"
          class="mx-auto"
          src="@/assets/images/recruit/image2.png"
        ></v-img>
      </v-container>
    </v-card>
    <v-container class="pa-0" style="margin-top: 40px">
      <v-row class="mx-0">
        <v-col cols="12" md="4" class="py-0">
          <p class="font-weight-bold font20 mb-4">
            {{ $t("渠道商开通的客服联系方式") }}
          </p>
          <p class="mb-3 d-flex align-center">
            <v-icon color="primary" size="25">mdi-phone</v-icon
            ><span class="font20 ml-2">15038350530</span>
          </p>
          <p class="mb-3 d-flex align-center">
            <v-icon color="primary" size="25">mdi-email</v-icon
            ><span class="font20 ml-2">97668216@qq.com</span>
          </p>
          <v-img width="210" src="@/assets/images/recruit/erweima.png"></v-img>
        </v-col>
        <v-col cols="12" md="8" class="py-0 d-flex justify-end">
          <div>
            <v-img
              max-width="700"
              src="@/assets/images/recruit/image3.png"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card
      class="rounded-0 text-center py-5"
      flat
      style="background: linear-gradient(207deg, #a692fd 0%, #6355ff 100%)"
    >
      <p class="font32 white--text mb-2 text-center">
        {{ $t("全球贸易，让您像做国内贸易一样简单！") }}
      </p>
      <p class="font20 mb-2 white--text text-center">
        {{ $t("加入taoify，轻松完成全球销售") }}
      </p>
      <v-btn
        large
        class="primary--text rounded-pill px-12"
        to="/sign-up"
        depressed
        >{{ $t("立即入驻") }}</v-btn
      >
    </v-card>
    <v-container style="padding-bottom: 66px">
      <p class="mt-16 text-center mb-0 font32">{{ $t("留电咨询 获取商机") }}</p>
      <p class="mb-11 text-center font32">{{ $t("享受蓝海 抢占先机") }}</p>
      <v-row>
        <v-col cols="6" md="4">
          <v-card height="56" flat color="#F7F7F7">
            <input :placeholder="$t('填写您所售商品')" />
          </v-card>
        </v-col>
        <v-col cols="6" md="4">
          <v-card height="56" flat color="#F7F7F7">
            <input :placeholder="$t('请输入您的电话号')" />
          </v-card>
        </v-col>
        <v-col cols="6" md="4">
          <v-card height="56" flat color="#F7F7F7">
            <input :placeholder="$t('请输入您的邮箱')" />
          </v-card>
        </v-col>
        <v-col cols="6" md="4">
          <v-card height="56" flat color="#F7F7F7">
            <input :placeholder="$t('填写您的所在地')" />
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card height="56" flat color="#F7F7F7">
            <input :placeholder="$t('填写您的企业信息')" />
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card
            height="56"
            flat
            class="cursor primary-background text-body-1 white--text text-center"
            style="line-height: 56px"
          >
            {{ $t("提交申请表") }}
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({}),
  methods: {},
};
</script>
<style lang="scss" scoped>
@media (min-width: 1445px) {
  #home-tops {
    width: 1445px !important;
    margin: 0 auto;
    .left {
      margin-left: 120px;
    }
  }
}
.color1 {
  background: #fafafa;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color2 {
  background: #f2f2f2;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>